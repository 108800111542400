// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-overview-management-pagelet-content[data-v-8d42b23e] {
  margin-bottom: 6px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.objective-overview-management-pagelet-content .ant5-spin-nested-loading[data-v-8d42b23e] {
  flex: 1;
}
.objective-overview-management-pagelet-content .ant5-spin-nested-loading .ant5-spin-container[data-v-8d42b23e] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,OAAO;AACT;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".objective-overview-management-pagelet-content[data-v-8d42b23e] {\n  margin-bottom: 6px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n.objective-overview-management-pagelet-content .ant5-spin-nested-loading[data-v-8d42b23e] {\n  flex: 1;\n}\n.objective-overview-management-pagelet-content .ant5-spin-nested-loading .ant5-spin-container[data-v-8d42b23e] {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
