// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-overview-management-pagelet-Bottom[data-v-96421c23] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  z-index: 1;
}
.objective-overview-management-pagelet-Bottom > a[data-v-96421c23] {
  padding: 4px;
  display: inline-flex;
  align-items: center;
}
.objective-overview-management-pagelet-Bottom > a[data-v-96421c23]:hover {
  background: rgba(0, 0, 0, 0.04);
  color: #333333 !important;
  border-radius: 6px;
}
.objective-overview-management-pagelet-Bottom > a p[data-v-96421c23] {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageBottom/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,UAAU;AACZ;AACA;EACE,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,SAAS;AACX","sourcesContent":[".objective-overview-management-pagelet-Bottom[data-v-96421c23] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-bottom: 12px;\n  z-index: 1;\n}\n.objective-overview-management-pagelet-Bottom > a[data-v-96421c23] {\n  padding: 4px;\n  display: inline-flex;\n  align-items: center;\n}\n.objective-overview-management-pagelet-Bottom > a[data-v-96421c23]:hover {\n  background: rgba(0, 0, 0, 0.04);\n  color: #333333 !important;\n  border-radius: 6px;\n}\n.objective-overview-management-pagelet-Bottom > a p[data-v-96421c23] {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
