import React, { useContext, useState } from 'react';
import { Dropdown, Avatar, Checkbox } from '@didi/ec-base';
import DebounceSelect from './debounceSelect';
import { PublicStore } from '../../../../utils';
import { items, enumText } from './utils';
import PageletServices from '../../../../services';
import classNames from 'classnames';
import './style.scoped.less';
import './style.less';

const FilterContent = () => {
  // 样式
  const prefixCls = 'filter-content';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const { searchType, searchValue, searchText, staffChildSearchTypes, onlyMustFillPost, newHeaders } = stateStore;
  const {
    params: { deptCodes = [] },
  } = props;
  const [hoverState, setHoverState] = useState(false);
  // 搜索员工
  const fetchUserList = async username => {
    // 有输入的情况下
    if (username) {
      try {
        const { data } = await PageletServices.postSearch('', newHeaders, {
          objectiveScene: 'DHR_WORKBENCH_MANAGE_WINDOW',
          pageNum: Number('1'),
          pageSize: Number('10'),
          deptCodes: deptCodes,
          keyword: username,
          searchType,
          onlyMustFillPost
        });
        return data.list.map(item => ({
          label: (
            <div className="searchItem">
              <div className="head">
                <Avatar
                  size={'small'}
                  src={item?.avatar || "//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"}
                />
              </div>
              <div className="people">
                <p className="fullName">
                  <span>{item?.name}</span>
                  <span className='line' />
                  <span>{item?.deptName}</span>
                </p>
                <div className="details">
                  <span className="jobID">{item?.emailAddr}</span>
                </div>
              </div>
            </div>
          ),
          text: item?.name,
          value: item?.ownerId,
        }));
      } catch (e) {
        // 抛出异常
        return [];
      }
    }
    return [];
  };
  // 结果请求
  const resultRequest = async params => {
    stateDispatch({ resultLoading: true });
    try {
      const paramsObj = {
        objectiveScene: 'DHR_WORKBENCH_MANAGE_WINDOW',
        pageNum: Number('1'),
        pageSize: Number('10'),
        deptCodes,
        ...params
      };
      const { data } = await PageletServices.postSearchAndDistribution('', newHeaders, paramsObj);
      const { progressDistribution, pageResult, searchParam } = data;
      const { distributionCode } = searchParam;
      stateDispatch({
        ...searchParam,
        distributions: progressDistribution?.distributions,
        idpNum: progressDistribution?.idpNum,
        idpPublishedNum: progressDistribution?.idpPublishedNum,
        totalNum: progressDistribution?.totalNum,
        distributionName: progressDistribution?.distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        resultLoading: false
      });
    } catch (e) {
      // 抛出异常
      return [];
    }
  };
  // 下拉筛选条件
  const dropdownChangeType = ({ key }) => {
    const params = { searchType: key, searchText: enumText[key], onlyMustFillPost };
    stateDispatch({ ...params, searchValue: '' });
    resultRequest({ ...params, ownerIds: [] })
  }
  // 人员搜索
  const searchPost = (newValue, optionValue) => {
    stateDispatch({
      searchValue: newValue,
      searchName: optionValue?.text
    });
    resultRequest({
      searchType,
      onlyMustFillPost,
      ownerIds: newValue ? [newValue] : []
    });
  };
  // 必填项点击事件
  const onlyMustFillPostChange = e => {
    stateDispatch({ onlyMustFillPost: e.target.checked });
    resultRequest({
      searchType,
      onlyMustFillPost: e.target.checked,
      ownerIds: searchValue ? [searchValue] : []
    });
  };

  return (
    <div className={classes}>
      {
        staffChildSearchTypes.length === 1 ? (
          <div className={`screen lowerIcon checkFilter`}>
            <p>{searchText || enumText[searchType]}</p>
          </div>
        ) : (
          <Dropdown
            menu={{
              selectedKeys: searchType,
              items: staffChildSearchTypes.map(type => items[type]),
              onClick: dropdownChangeType,
            }}
          >
            <div className={`screen lowerIcon ${staffChildSearchTypes.includes(searchType) && 'checkFilter'}`}
              onMouseEnter={() => setHoverState(true)}
              onMouseLeave={() => setHoverState(false)}
            >
              <p>{searchText || enumText[searchType]}</p>
              {(hoverState || staffChildSearchTypes.includes(searchType))
                ? <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_W6nUTG5j6mhVvHuulVdM'} />
                : <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_29ftiY2RZCRT98ssn4hu'} />
              }
            </div>
          </Dropdown>
        )
      }
      <div>
        <DebounceSelect
          value={searchValue || null}
          fetchOptions={fetchUserList}
          onChange={searchPost}
          suffixIcon={<img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_44lPDL4WGzMKYiABjsNh'} />}
        />
      </div>
      <div>
        <Checkbox
          checked={onlyMustFillPost}
          onChange={onlyMustFillPostChange}
          className='objective-overview-management-by-post'
        >仅看必填岗位</Checkbox>
      </div>
    </div>
  );
};

export default FilterContent;
