// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-overview-management-pagelet-header[data-v-6ff9f326] {
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.objective-overview-management-pagelet-header .diffTips[data-v-6ff9f326] {
  cursor: pointer;
  z-index: 10;
  margin-left: 6px;
}
.objective-overview-management-pagelet-header .monthUrl[data-v-6ff9f326] {
  width: 86px;
  height: 28px;
  position: absolute;
  top: -4px;
  right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".objective-overview-management-pagelet-header[data-v-6ff9f326] {\n  padding-left: 16px;\n  padding-right: 16px;\n  position: relative;\n  margin-bottom: 6px;\n  display: flex;\n  align-items: center;\n}\n.objective-overview-management-pagelet-header .diffTips[data-v-6ff9f326] {\n  cursor: pointer;\n  z-index: 10;\n  margin-left: 6px;\n}\n.objective-overview-management-pagelet-header .monthUrl[data-v-6ff9f326] {\n  width: 86px;\n  height: 28px;\n  position: absolute;\n  top: -4px;\n  right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
