import React, { useContext } from 'react';
import { PublicStore } from '../../utils';
import classNames from 'classnames';
import './style.scoped.less';


const PageHeader = () => {
  // 样式
  const prefixCls = 'objective-overview-management-pagelet-header';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { monthUrl, cycleUrl } = apolloConfig;

  return (
    <div className={classes}>
      <img height='28' src={cycleUrl} />
      {monthUrl && <img className='monthUrl' src={monthUrl} alt="" />}
    </div>
  );
};

export default PageHeader;
