// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IDP-data[data-v-0994a636] {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: rgba(245, 245, 245, 0.6);
}
.IDP-data p[data-v-0994a636] {
  margin: 0;
  font-family: PingFang SC, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.84);
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/IDP/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,SAAS;EACT,oCAAoC;EACpC,eAAe;EACf,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".IDP-data[data-v-0994a636] {\n  margin-top: 4px;\n  margin-left: 10px;\n  margin-right: 10px;\n  padding: 5px 14px;\n  display: flex;\n  justify-content: space-between;\n  border-radius: 8px;\n  background: rgba(245, 245, 245, 0.6);\n}\n.IDP-data p[data-v-0994a636] {\n  margin: 0;\n  font-family: PingFang SC, sans-serif;\n  font-size: 12px;\n  line-height: 18px;\n  color: rgba(0, 0, 0, 0.84);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
