// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-overview-management-by-post {
  align-items: center;
}
.objective-overview-management-by-post .ant5-checkbox .ant5-checkbox-inner {
  width: 12px;
  height: 12px;
  border-radius: 1.7px;
}
.objective-overview-management-by-post .ant5-checkbox .ant5-checkbox-inner::after {
  border-width: 1px !important;
  width: 4px;
  height: 8px;
  top: 47%;
}
.objective-overview-management-by-post span {
  font-family: PingFang SC, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.84);
}
.objective-overview-management-by-post > span:last-child {
  padding-inline-start: 4px;
  padding-inline-end: 0px;
}
.ant5-select-dropdown {
  width: 238px !important;
}
.objective-overview-management-debounce-select .ant5-select-arrow {
  right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/FilterContent/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,4BAA4B;EAC5B,UAAU;EACV,WAAW;EACX,QAAQ;AACV;AACA;EACE,oCAAoC;EACpC,eAAe;EACf,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,UAAU;AACZ","sourcesContent":[".objective-overview-management-by-post {\n  align-items: center;\n}\n.objective-overview-management-by-post .ant5-checkbox .ant5-checkbox-inner {\n  width: 12px;\n  height: 12px;\n  border-radius: 1.7px;\n}\n.objective-overview-management-by-post .ant5-checkbox .ant5-checkbox-inner::after {\n  border-width: 1px !important;\n  width: 4px;\n  height: 8px;\n  top: 47%;\n}\n.objective-overview-management-by-post span {\n  font-family: PingFang SC, sans-serif;\n  font-size: 12px;\n  line-height: 18px;\n  color: rgba(0, 0, 0, 0.84);\n}\n.objective-overview-management-by-post > span:last-child {\n  padding-inline-start: 4px;\n  padding-inline-end: 0px;\n}\n.ant5-select-dropdown {\n  width: 238px !important;\n}\n.objective-overview-management-debounce-select .ant5-select-arrow {\n  right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
