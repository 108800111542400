import React, { useEffect, useReducer, useMemo } from 'react';
import { ConfigProvider, Spin } from '@didi/ec-base';
import { utils, newRequest } from '@didi/pagelets-sdk'; // 国际化翻译文件
import { PublicStore, getApolloConfig } from './utils';
import { enumText } from './components/PageContent/components/FilterContent/utils';
import PageletServices from './services';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面内容
import PageBottom from './components/PageBottom'; // 页面底部
import classNames from 'classnames';
import './style.scoped.less';

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {}, deptCodes = [] },
  } = props;

  // 获取组件的id
  const { functionId, functionName } =
    utils.getFunctionData(auth, '@didi/pagelet-objective-overview-management') || {};
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  // 初始化请求头
  newRequest.init(newHeaders);

  // 样式
  const prefixCls = 'objective-overview-management';
  const classes = classNames(prefixCls);
  // 全局数据
  const [stateStore, stateDispatch] = useReducer(
    (stateStore, action) => ({ ...stateStore, ...action }),
    {
      loading: true,
      resultLoading: false,
      apolloConfig: {
        // apollo 配置信息
        pageLetInfo: {
          // 组件的信息
          cycleBanner: ['暂无内容'],
        },
        helpInfo: {}, // 帮助信息
        monthUrl: '',
        cycleUrl: '',
      },
      // 搜索人员
      searchValue: '',
      searchName: '',
      // 筛选条件
      searchType: '',
      searchText: '',
      onlyMustFillPost: false,
      // 选中状态
      distributionCode: '',
      distributionName: '',
      // 状态图数据
      distributions: [],
      totalNum: 0,
      idpNum: 0,
      idpPublishedNum: 0,
      // 人员列表
      searchLoading: false,
      pageNum: 0,
      list: [],
      hasMore: true,
      // 筛选条件
      staffChildSearchTypes: [],
      newHeaders
    },
  );
  // 存储全局数据
  const contextValue = useMemo(() => ({ stateStore, stateDispatch, props }), [stateStore]);
  const { loading } = stateStore;

  // 初始化接口请求
  const handleInitRequest = async () => {
    try {
      const res = await PageletServices.getDept('', {});
      const { data } = await PageletServices.postManager('', newHeaders, {
        deptCodes,
        topDeptCodes: res?.data.map(item => item?.deptCode),
      });
      const { progressDistribution, searchParam, pageResult, staffChildSearchTypes } = data;
      const { distributions, totalNum, idpNum, idpPublishedNum } = progressDistribution;
      const { distributionCode } = searchParam;
      const findType = staffChildSearchTypes.find(item => item.selected);
      stateDispatch({
        ...searchParam, // 筛选条件
        distributions, // 状态图
        totalNum, // 柱状图数量
        idpNum,
        idpPublishedNum,
        staffChildSearchTypes: staffChildSearchTypes.map(item => item.searchType), // 筛选条件
        searchText: enumText[findType?.searchType],
        distributionName: distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        loading: false,
      });
    } catch (e) {
      stateDispatch({ loading: false });
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(async () => {
    handleInitRequest();
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  return (
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          {loading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <>
              <PageHeader />
              <PageContent />
              <PageBottom />
            </>
          )}
        </div>
      </PublicStore.Provider>
    </ConfigProvider>
  );
};

export default Pagelet;
